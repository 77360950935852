@import 'src/theme/color';
@import "src/theme/breakpoints";
@import "src/theme/utilities";

.componentWrapper {
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;
  height: $bottom-menu-height;
  width: 100%;
  background: $color-white;
  box-shadow: 0px -6px 10px -1px rgba(6, 25, 56, 0.05);
  z-index: 130;

  @include breakpoint(md) {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .menuItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    font-size: 12px;
    height: 44px;

    svg {
      font-size: 24px;
    }

    &:hover,
    &.active {
      color: $color-secondary;
    }
  }
}

.wrapperComponent {}

.inputs,
.fields {

  textarea,
  input {
    width: 100%;
  }
}

.inputWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.dateWrapper {
  display: flex;

  button {
    margin-right: 10px;
  }
}

.active {
  margin-left: 30px;
  display: flex;
  align-items: center;
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.fileWrapper {
  img {
    max-height: 150px;
  }
}
@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: block;
  width: 100%;
  background-color: $color-gray-background;
  height: 456px;

  @include breakpoint(md) {
    height: auto;
  }
}

.slider {
  .slide {
    height: 410px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    .slideLink {
      display: block;
      height: 100%;
    }
  }

  .sliderLinks {
    width: 100%;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(md) {
      overflow-x: auto;
      max-width: 100vw;
      justify-content: flex-start;
    }

    button {
      white-space: nowrap;
      margin: 0 5px;
      border: none;
      background: transparent;
      font-weight: 400;
      font-size: 16px;
      color: $color-label;
    }
  }
}

.sidebar {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.counterWrapper {
  padding: 37px;
  z-index: 1;

  @include breakpoint(md) {
    padding: 20px 10px 36px;
  }
  font-family: $font-poppins;
  font-weight: 700;

  .title {
    font-size: 24px;
    line-height: 120%;
    color: $color-title-counter;
    margin-bottom: 24px;
  }

  .counter {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;

    .number {
      margin: 3px;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      background: $color-white;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
      font-size: 32px;
      color: $color-text-dark;
      min-width: 38px;
    }
  }
}

.newsBlock {
  padding: 37px 37px 0;
  background-color: $color-white;
  flex: 1;

  @include breakpoint(md) {
    display: none;
  }
}

.title {
  display: block;
  width: 100%;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: $color-text-dark;
  text-decoration: none;
}

.newsWrapper {
  width: 100%;
  margin-top: 24px;
}

.news {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: $color-text-dark;
  cursor: pointer;
  margin-bottom: 14px;

  span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
     text-overflow: ellipsis;
     overflow: hidden;
     word-wrap:break-word;
  }

  &:hover {
    text-decoration: underline;
  }

  svg {
    display: block;
    color: $color-news;
    margin-left: auto;
    font-size: 20px;
    margin-top: -8px;
  }
}

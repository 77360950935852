@import 'src/theme/color';

.componentWrapper {
  input,
  .input {
    height: 36px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 16px;
    font-size: 14px;
    border-radius: 0;
    border: 1px solid $color-gray-border;
    background: $color-white;
    color: $color-text;
    outline: none;
    cursor: pointer;

    &.error {
      border: 1px solid $color-error;
    }
  }

  .inputWrapper {
    display: flex;

    button {
      height: 36px;
    }
  }
}

.actionWrapper {
  display: flex;
  justify-content: flex-end;
}

@import 'src/theme/breakpoints';

.wrapperComponent {
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  padding: 53px 0;
  overflow-x: auto;

  @include breakpoint(md) {
    padding: 45px 8px;
  }

  > div {
    width: 100%;

    @include breakpoint(md) {
      > div {
        justify-content: space-between !important;
        > div {
          margin: 0 15px;
          text-align: center;

          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

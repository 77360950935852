@import 'src/theme/color';

.wrapperComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.formElement {
  width: 100%;
  &.formElementHalfWidth {
    width: calc(50% - 10px);
  }

  margin-bottom: 12px;

  label {
    font-weight: 700;
  }
}

.actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;

  button + button {
    margin-left: 16px;
  }
}

.error {
  color: $color-error;
  font-size: 12px;
  padding: 4px 0;
}

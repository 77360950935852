@import 'src/theme/color';

.wrapperComponent {

  .authButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    font-size: 14px;
    color: $color-text-light;
    margin-left: 12px;
    cursor: pointer;

    svg {
      font-size: 20px;
      margin-left: 12px;
    }
  }
}




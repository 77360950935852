@import 'src/theme/color';


.mobileWrapper {
  padding: 16px 17px 20px;
  margin-bottom: 8px;
  background-color: $color-white;

  .mobileRow {
    font-size: 13px;
    line-height: 120%;
    color: $color-text;
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      padding: 16px 0 0;
    }

    .title {
      color: $color-text-dark;
    }

    .minor {
      font-size: 12px;
    }

    .textPadding {
      padding-bottom: 3px;
    }

    .small {
      font-size: 10px;
      font-weight: 300;
    }

    .label {
      color: $color-text-light;
      margin-bottom: auto;
      padding-right: 8px;
    }

    .productWrapper {
      display: flex;
      margin-left: 17px;
      gap: 8px;
      width: 100%;
      justify-content: space-between;

      .unitWrapper {
        display: flex;

        .unitData {
          text-align: center;
        }
      }
    }

    .priceWrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .borderPrice {
        height: auto;
        width: 1px;
        margin: 0 10px;
        background-color: $color-table-border;
      }

      .unitPrice {
        // border-right: 1px solid $color-table-border;

        div {
          &:not(:first-child) {
            margin-left: 10px;
          }
        }
      }
      .totalPrice {
        display: flex;
      }
    }

    img {
      width: 65px;
      height: 65px;
    }

    &:not(:last-child) {
      border-bottom: 1px dashed $color-table-border;
    }
  }
}
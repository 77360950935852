@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  display: block;
  width: 100%;
}

.importWrapper {
  display: block;
  width: 100%;
  padding: 20px;
  background-color: $color-white;

  .examples {
    margin-top: 16px;
    text-align: center;

    a {
      margin-left: 12px;
      color: $color-secondary;
      border: none;
      background: none;
      text-decoration: none;
      font-weight: 700;
    }
  }
}

.title {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: $color-text-dark;
}

.form {
  display: flex;
  flex-flow: wrap row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 32px;

  @include breakpoint(md) {
    flex-direction: column;

    button {
      margin: 4px 0;
    }
  }
}

.modalBody {
  display: flex;
  flex-flow: wrap column;
  width: 100%;
  padding: 0 10px;
}

.formWrapper {
  display: flex;
  flex-flow: wrap column;
  width: 100%;
  max-width: 240px;
  margin: 0 auto 24px;

  & > button {
    margin-top: 12px;
  }
}

.modalTable {
  display: block;
  width: 100%;
  max-height: 290px;
  margin-top: 24px;
  overflow-x: auto;

  @include breakpoint(md) {
    max-height: calc(100vh - 300px);
  }
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.light {
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: $color-text;
}

.dark {
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: $color-text-dark;
}

.text {
  display: flex;
  justify-content: center;
  width: 100%;
  font-weight: 700;
}

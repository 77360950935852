.wrapperComponent {
  padding: 20px 0;
  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: 50px;
  }
}

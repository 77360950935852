.wrapperComponent {
  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;

    button + button {
      margin-left: 16px;
    }
  }
}

@import 'src/theme/color';

.componentWrapper {
  input,
  .input {
    min-height: 36px;
    max-height: 60px;
    overflow-y: scroll;
    width: 100%;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 0;
    border: 1px solid $color-gray-border;
    background: $color-white;
    color: $color-text;
    outline: none;
    cursor: pointer;

    * {
      margin: 0;
    }

    &.error {
      border: 1px solid $color-error;
    }
  }

  .inputWrapper {
    display: flex;

    button {
      height: unset;
    }
  }
}

.actionWrapper {
  display: flex;
  justify-content: flex-end;
}

@import 'src/theme/color';
@import 'src/theme/font';

.wrapperComponent {
  display: block;
}

.header {
  font-family: $font-poppins;
  display: flex;
  justify-content: center;
  padding: 14px 0 0;
  margin-bottom: -14px;
  font-size: 18px;
  line-height: 140%;
}

.form {
  width: 100%;
  max-width: 300px;
  min-width: 300px;
  margin: 50px auto;
  padding: 0 32px;

  .rememberMe {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-label;

    svg {
      width: 20px;
      height: 20px;
      margin-right: -5px;
    }
    margin-bottom: 38px;
  }

  .actions {
    margin: 16px 0;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;

    button {
      width: 100%;
    }

    .link {
      color: $color-primary;
      text-decoration: none;
    }
  }

  .register {
    h2 {
      margin-bottom: 10px;
    }

    button {
      width: 100%;
    }
  }
}

@import 'src/theme/color';

.wrapperComponent {
  display: block;
}

.notifications {
  background: $color-white;
  padding: 32px;

  .notification {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      width: auto;
    }
  }
}

@import 'src/theme/color';
@import 'src/theme/breakpoints';
@import 'src/theme/utilities';
@import 'src/theme/font';

.componentWrapper {
  display: flex;
  align-items: center;
  height: 75px;
  color: $color-text;
  border-bottom: 1px solid $color-gray-border;
  position: relative;

  @include breakpoint(md) {
    background: $color-gray-background;
    border: none;
    box-shadow: inset 0 13px 34px -40px rgba(0, 0, 0, 1);
    padding: 17px 16px;
    height: auto;
    overflow-x: auto;
  }

  .bottomBar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      display: inline-flex;
      align-items: center;
      height: 100%;
    }

    @include breakpoint(md) {
      min-width: 100%;
      overflow: auto;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }

      .categoriesWrapper {
        display: none;
      }
    }
  }

  .categoriesWrapper {
    .categoryButtonLabel {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-family: $font-poppins;

      svg {
        margin-left: 12px;
      }
    }
  }

  .link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: $color-text-dark;
    margin-left: 64px;
    flex-wrap: nowrap;

    @include breakpoint(md) {
      font-size: 16px;
      margin-left: 0;
      margin-right: 24px;
    }

    &.big {
      font-size: 16px;

      svg {
        font-size: 20px;
        margin-right: 8px;
      }
    }

    &.active {
      font-weight: 700;
      text-decoration: underline;
    }
  }
}

.categoriesPopup {
  position: absolute;
  top: 75px;
  left: 0;
  width: 100%;
  z-index: 100;
  background: $color-overlay;
  height: calc(100vh - $header-height-desktop);

  .content {
    padding: 24px 0;
    background: $color-white;
    max-height: calc(100vh - $header-height-desktop - 24px);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    overflow: auto;
  }
}

.category {
  position: relative;
  padding-left: 20px;
  margin-top: 4px;

  &.main {
    padding-bottom: 8px;

    > a {
      font-size: 16px;

      .counter {
        font-size: 14px;
      }
    }
  }

  a {
    border: none;
    background: transparent;
    cursor: pointer;
    text-align: left;
    font-size: 14px;
    color: initial;
    font-family: 'Arial';
    text-decoration: none;
    padding: 1px 6px;
    display: inline-block;

    &:hover {
      color: $color-secondary;
    }

    &.chosen {
      color: $color-secondary;
      font-weight: 700;
    }

    .counter {
      font-size: 12px;
    }
  }

  svg {
    position: absolute;
    top: 1px;
    left: 0;
    font-size: 16px;
    cursor: pointer;
    color: $color-secondary;
  }
}

@import 'src/theme/color';
@import 'src/theme/font';

.wrapperComponent {
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  padding: 53px 0 0;
}

.productTabsWrapper {
  width: 100%;
  background-color: $color-white;

  .tabsBar {
    border-bottom: 1px solid $color-collapsed-border;
    overflow-x: auto;
  }

  .tabs {
    width: fit-content;
    height: 60px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    background: $color-primary;
    margin-right: auto;
    padding: 0 36px;
  }

  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 16px;
    color: $color-white;
    font-weight: 500;
    width: 150px;
    cursor: pointer;

    &.active {
      color: $color-primary;
      background: $color-white;
      font-weight: 700;
      font-family: $font-poppins;
    }
  }
}

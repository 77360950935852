@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  display: flex;
  align-items: center;
  height: 40px;
  color: $color-text;
  border-bottom: 1px solid $color-gray-border;

  @include breakpoint(md) {
    display: none;
  }

  .topBar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
    }

    .user {
      margin-right: 24px;
    }
  }

  .overloginWrapper {
    margin-left: 16px;
  }

  .overlogin {
    color: $color-secondary;
    cursor: pointer;
  }

  .authButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    font-size: 14px;
    color: $color-text-light;
    margin-left: 12px;
    cursor: pointer;

    svg {
      font-size: 20px;
      margin-left: 12px;
    }
  }
}

.language {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 8px;
    width: 25px;
    border: 1px solid $color-secondary-border;
  }
}

.link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $color-text-dark;
  text-decoration: none;
  margin-right: 20px;
  font-size: 12px;

  &.active {
    color: $color-primary;
  }

  svg {
    font-size: 20px;
    margin-right: 8px;
  }
}

.overloginTooltip {
  padding: 10px 0;
  div {
    padding: 0 10px;
  }

  span {
    font-size: 1rem;
    color: $color-black;
  }
}

.overloginButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  font-size: 14px;
  height: 25px;
  font-weight: 400;
  color: $color-text;
  cursor: pointer;

  svg {
    font-size: 16px;
    margin-left: 10px;
  }
}

.filtersWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  button span {
    font-weight: 700;
  }
}

.selectWrapper {
  display: flex;
  align-items: center;
}

.overLoginTableWrapper {
  display: block;
  width: 100%;

  table {
    filter: drop-shadow(0px 2px 5px rgba(6, 25, 56, 0.06));

    button {
      height: 30px;
    }
  }

  thead {
    tr {
      th {
        padding: 10px 18px;
        background-color: $color-white;
        border-bottom: 1px solid $color-gray-border;
      }
    }
  }

  tbody {
    tr:nth-child(2n) {
      td {
        background-color: $color-gray-background;
      }
    }
  }

  tr {
    td {
      padding: 2px 18px;
    }

    td:first-child {
      border-right: 1px dashed $color-gray-border;
    }
  }
}

.userListWrapper {
  display: flex;
  justify-content: space-between;
}

.link {
  padding: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: $color-shopping-button;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.confirmationModalActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;

  button + button {
    margin-left: 16px;
  }
}

$color-error: #C71717;
$color-success: #44D038;

$color-body-bg: #DEDEDE; //gray10

$color-table: #DEDEDE; //gray10

$color-gray-background-dark: #E7E9EE;
$color-gray-background: #F3F4F7; //gray50
$color-gray-border: #cccfd5; //gray300 borderGray

$color-product-bg: #f4f4f4; //backgroundGray

$color-black: #000000;
$color-main-black: #20262E;
$color-orange: #FF8C39;
$color-blue-promotion: #39B0D9;
$color-woodsmoke: #15181e;

$color-overlay: rgba(158, 158, 158, 0.5);

$color-primary: #173EC7;

$color-accent: #CAE041;
$color-violet: #CD006C;

$color-secondary: #4917C7;
$color-secondary-background: #EEE6FA; //secondary50
$color-secondary-border: #B598EA; //secondary200

$color-collapsed-border: #D9D9D9; //collapseBorder

$color-text: #55565A; //gray800
$color-text-dark: #383A3C; //gray900
$color-text-light: #8D9096; //gray600
$color-label: #717378; //gray700

$color-white: #ffffff;
$color-black: #000000;
$color-red: #E02E4E;


$color-gray: #f9f9f9;
$color-gray-checkbox: #A1A4AB; //gray500

$color-footer-background: #1436AD;
$color-footer-background-dark: #112E94;

$color-stock-low: #D3C1F2;
$color-stock-middle: #B598EA; //secondary200
$color-stock-big: #6525D5;

// alerty

$color-alert-error-bg: #F8E8E8;
$color-alert-error-border: #DB8C8C;
$color-alert-success-bg: #E7F4E7;
$color-alert-success-border: #87C987;
$color-alert-warning-bg: #FFF4E5;
$color-alert-warning-border: #FFB142;

$color-counter: #f4f4f4;

$color-dropdown: #976CE3; //secondary300 secondary400

$color-file-bg: #e7e9ee; //gray100
$color-file-text: #976CE3; //secondary300 secondary400
$color-file-border: #717378;

$color-summary-border: #DBDEE4; //gray200
$color-faq-active: #91A9FF;
$color-slider-tray: #91A9FF;

$color-selected-bg:#D3C1F2;

$color-news: #1A45DF;
$color-title-counter: #5E82FF;
$color-slider-dot: #1D4EFA;
$color-block: #C4D1FF;
$color-link: #976CE3; //secondary300-400

$color-bestseller: #B598EA; //secondary200
$color-product-hover: #D3C1F2; //secondary100

$color-text-search: #333333;
$color-search-border: #D3C1F2; //secondary100

$color-loader : #976CE3; //secondary300 secondary400
$color-info : #976CE3; //secondary300 secondary400


$color-form-border: #DBDEE4; //gray200

$color-status: #A1A4AB; //gray500

$color-order-bg: #f4f4f4;

$color-tab-bg: #F3F4F7;
$color-tab-border: #E7E9EE; //gray100

$color-table-border: #DBDEE4; //gray200


$color-shopping-button: #1436AD;

$color-search: #d8dce4;
$color-product-id: #1436AD;

$color-price-border: #D8DCE4;

$color-bin: #B81919;

$color-sort-active: #6525D5;
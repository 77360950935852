@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.price {
  display: flex;
  flex-flow: wrap row;
  justify-content: space-between;
  width: 100%;
  padding-top: 25px;
}

.wrapperComponent {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  border-left: 1px solid $color-gray-border;

  @include breakpoint(md) {
    border-left: none;
  }

  .mainContent {
    padding-left: 35px;
    width: 100%;
    flex: 1;

    @include breakpoint(md) {
      padding-left: 0;
      margin: 8px;
      width: calc(100% - 16px);
    }

    .promotion {
      margin-left: auto;
      border: 1px solid $color-blue-promotion;
      color: $color-blue-promotion;
      padding: 8px 40px;
      margin-top: 24px;
      width: fit-content;
    }

    .actionsWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      margin-bottom: 18px;
      margin-top: 8px;

      .quantityCounter {
        width: 130px;
        height: 54px;
      }

      .loginButton {
        text-decoration: none;
        color: $color-primary;
        margin-right: 64px;

        @include breakpoint(md) {
          margin-right: 16px;
        }
      }
    }
  }
}

.text {
  font-family: $font-poppins;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: $color-main-black;
}

.groupPrice {
  display: flex;
  flex-flow: wrap column;
  align-items: flex-end;
}

.old {
  display: inline;
  margin-right: 14px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: line-through;
  color: $color-label;
}

.net {
  display: block;
  font-family: $font-poppins;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color-woodsmoke;

  strong {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }
}

.gross {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $color-label;
}

.tableWrapper {
  padding-top: 32px;

  table {
    width: 100%;

    th {
      font-weight: 400;
      text-align: right;
      padding-bottom: 8px;

      span {
        display: inline-block;
        font-size: 12px;
        color: $color-text;
      }
    }

    td {
      text-align: right;
      padding: 8px 0;
      border-top: 1px dashed $color-gray-border;
      color: $color-text;
    }

    .left {
      text-align: left;
    }

    .nameWrapper {
      display: inline-flex;
      align-items: center;
    }

    .current {
      td {
        font-weight: 700;
        color: $color-text-dark;
      }
    }
  }
}

.dashedBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px dashed $color-gray-border;
  min-height: 48px;
  padding: 4px 35px;

  @include breakpoint(md) {
    padding: 4px 8px;
  }

  .item {
    display: inline-flex;
    align-items: center;
    margin-right: 20px;
    font-size: 12px;
    color: $color-text;

    strong {
      margin: 0 4px;
    }

    &:last-child {
      margin-right: 0;
    }

    > svg {
      font-size: 22px;
      margin-right: 10px;
    }

    &.button {
      cursor: pointer;

      > svg {
        color: $color-secondary;
      }
    }
  }
}

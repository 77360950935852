@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 1px solid $color-secondary-background;
  border-bottom: 1px solid $color-secondary-background;
  background: $color-white;
  font-size: 13px;
  color: $color-text-dark;

  @include breakpoint(md) {
    flex-direction: column;
    border-top: 1px solid $color-gray-border;
    border-bottom: 1px solid $color-gray-border;
  }

  > div {
    width: 20%;
    padding: 12px;

    @include breakpoint(md) {
      width: calc(100% - 32px);
      margin: 0 16px;
      padding: 16px 0;
      border-bottom: 1px dashed $color-gray-border;

      &:last-child {
        border-bottom: 0;
      }

      .price {
        text-align: right;
        font-size: 14px;
        font-weight: 700;
      }
    }

    &:first-of-type {
      flex: 1;
    }
  }

  .title {
    font-size: 12px;
    color: $color-label;
    margin-bottom: 12px;
  }

  .label {
    font-weight: 700;
  }
}

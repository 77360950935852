@import 'src/theme/color';

.wrapperComponent {
  display: flex;
}

.button {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  color: $color-text;
  background: $color-file-bg;
  border: 1px solid $color-file-border;
  cursor: pointer;

  > svg {
    margin-right: 8px;
  }
}

.label {
  display: inline;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: $color-text;
}

.file {
  display: flex;
  flex-flow: wrap row;
  align-items: center;
  margin-left: 40px;

  font-weight: 400;
  font-size: 14px;
  line-height: 140%;

  color: $color-file-text;

  > svg {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700&display=swap');
@import 'src/theme/color';

html {
  min-height: 100vh;
}

body {
  height: 100vh;
  margin: 0;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $color-body-bg;
}

* {
  box-sizing: border-box;
}

#root {
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: $color-secondary-background;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: $color-secondary;
  border-radius: 8px;
}

.focusRing___1airF.carousel__slide-focus-ring {
  outline: none !important;
}

.rdp-day_selected,
.rdp-day_range_start {
  border-radius: 100% !important;
}

.thin {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}
.bold {
  font-weight: 700;
}

.MuiSwitch-root {
  .MuiSwitch-track {
    background-color: $color-primary;
  }
}

.StylePath-Pages-Home-components-Banner .carousel__dot--selected {
  font-weight: 800 !important;
  color: $color-slider-dot !important;
}

.SnackbarItem-message {
  white-space: pre-wrap;
}

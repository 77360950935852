@import 'src/theme/color';
@import 'src/theme/breakpoints';


.wrapperComponent {
  display: block;
};

.form {
  display: flex;
  justify-content: space-between;
  border: 1px solid $color-form-border;

  @include breakpoint(md) {
    background-color: $color-white;
  }

  .searchButton {
    background-color: transparent;
    margin-right: 3px;
    width: 45px;

    &:hover {
      background-color: transparent;
    }

    svg {
      font-size: 16px;
    }
  }
};

.input {
  width: 255px;
  height: 41px;
  padding: 3px 10px 0;
  border: none;
  border-right: 1px solid $color-form-border;
  font-size: 14px;
  font-weight: 400;
  color: $color-text-dark;
  flex: 1;
  outline: none;

  @include breakpoint(md) {
    border-right: none;
  }

};
@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 24px;
  background: $color-white;

  .summary-blocks {
    display: flex;
    .summary-entities {
      min-height: 350px;
      padding: 24px 0 0 34px;

      @include breakpoint(md) {
        padding: 24px 0 0 16px;
        order: 2;
      }

      > div {
        display: flex;
        justify-content: start;
      }
      > div > div {
        padding: 0 0 24px 0;
        border-right: unset;
      }
    }

    .summary-status {
      background: $color-gray-background;

      @include breakpoint(md) {
        width: 100%;
        height: auto;
        margin-left: 16px;
        padding-left: 0;
        padding-right: 16px;
        justify-content: space-between;
        background: $color-white;
        border-bottom: 1px dashed #cccfd5;
        order: 1;
        padding-top: 30px;
        padding-bottom: 30px;

        .box {
          &:first-child {
            order: 1;
          }
          &:nth-child(2) {
            order: 3;
          }
          &:last-child {
            order: 2;
          }
        }
      }

      display: flex;
      flex-wrap: wrap;

      height: 100%;
      padding-left: 50px;

      .status-small {
        height: 14px;
      }
    }
  }
}

.box {
  min-width: 200px;

  &:first-of-type {
    margin-top: 30px;
  }

  @include breakpoint(md) {
    margin-top: 0 !important;
    margin-bottom: 0;
    min-width: 0;
  }
}

.blocks {
  display: flex;
  flex-flow: nowrap row;
  width: 100%;
}

.title {
  display: inline-block;
  font-family: $font-poppins;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: $color-primary;
  font-size: 18px;
  width: 100%;
  margin-bottom: 20px;

  @include breakpoint(md) {
    color: $color-text-dark;
    font-weight: 500;
  }

  &.mainTitle {
    color: $color-primary;
    margin-top: 20px;
    padding-left: 16px;
    padding-bottom: 20px;
    border-bottom: 1px solid $color-gray-border;
  }
}

.name {
  font-weight: 700;
  padding-right: 16px;
  margin-bottom: 10px;
  font-size: 16px;

  @include breakpoint(md) {
    color: $color-text-light;
    font-weight: 400;
  }

  &.small {
    font-size: 14px;
    margin-bottom: unset;
  }
}

.historyName {
  font-size: 14px;
  white-space: nowrap;
  padding-right: 3px;
}

.list {
  display: flex;
  align-items: baseline;
  width: 100%;
  margin-left: 34px;

  @include breakpoint(md) {
    margin-left: 16px;
    flex-direction: column;

    .name {
      color: $color-text-dark;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }

  padding: 20px 0;
  border-top: 1px dashed $color-gray-border;
}

.history {
  display: flex;
  flex-wrap: wrap;
}

.event {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 13px;
  margin-right: 16px;

  @include breakpoint(md) {
    gap: 15px;
  }

  .date {
    @include breakpoint(md) {
      width: auto;
    }

    span {
      color: $color-label;
    }
  }
}

.value {
  font-size: 16px;

  &.largeValue {
    font-size: 18px;
    font-weight: 700;
  }

  span {
    display: inline-block;
    text-align: right;
    margin-right: 3px;
    min-width: 80px;
  }

  .large {
    font-size: 18px;
  }
}

.statusWrapper {
  display: block;
}

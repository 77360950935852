@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
}

.filtersWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  @include breakpoint(md) {
    display: block;
    padding: 0 16px 8px;
    text-align: end;

    > :first-child {
      margin-bottom: 16px;
    }
  }

  button:first-of-type:not(:last-child) {
    margin-right: 10px;
  }
}

.colored {
  &.primary {
    color: $color-primary;
  }

  &.secondary {
    color: $color-secondary;
  }

  &.success {
    color: $color-success;
  }

  &.error {
    color: $color-error;
  }
}

.cliackable {
  cursor: pointer;
}

.editButton {
  border: none;
  background: transparent;
  color: $color-secondary;
  font-size: 18px;
  cursor: pointer;
}

.tableWrapper {
  display: block;
  width: 100%;

  table {
    filter: drop-shadow(0px 2px 5px rgba(6, 25, 56, 0.06));
  }

  thead {
    tr {
      th {
        padding: 10px 18px;
        background-color: $color-white;
        border-bottom: 1px solid $color-gray-border;
      }
    }
  }

  tbody {
    tr:nth-child(2n) {
      td {
        background-color: $color-gray-background;
      }
    }
  }

  tr {
    td {
      padding: 10px 18px;
    }

    td:first-child {
      border-right: 1px dashed $color-gray-border;
    }
  }
}

@import 'src/theme/color';

.wrapperComponent {
  display: block;
}

.info {
  display: flex;
  align-items: flex-start;
  color: $color-info;
  padding: 8px;
  background: $color-gray-background;
  border: 1px solid $color-secondary-background;
  box-shadow: 0 1px 2px rgba(6, 25, 56, 0.05);
  border-radius: 2px;

  svg {
    flex-shrink: 0;
    margin-right: 12px;
  }
}

.category {
  position: relative;
  padding-left: 20px;
  margin-top: 4px;

  &.main {
    padding-bottom: 8px;

    > a {
      font-size: 16px;

      .counter {
        font-size: 14px;
      }
    }
  }

  a {
    border: none;
    background: transparent;
    cursor: pointer;
    text-align: left;
    font-size: 14px;
    color: initial;
    font-family: 'Arial';
    text-decoration: none;
    padding: 1px 6px;
    display: inline-block;

    &:hover {
      color: $color-secondary;
    }

    &.chosen {
      color: $color-secondary;
      font-weight: 700;
    }

    .counter {
      font-size: 12px;
    }
  }

  svg {
    position: absolute;
    top: 1px;
    left: 0;
    font-size: 16px;
    cursor: pointer;
    color: $color-secondary;
  }
}

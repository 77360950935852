@import 'src/theme/color';

.wrapperComponent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .progress {
    width: 50px;
    height: 50px;
    margin-bottom: 16px;
    color: $color-gray-checkbox;
  }
};
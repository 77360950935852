@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  display: block;
  width: 100%;
}

.icon {
  display: inline-block;
}

.summary {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-bottom: 34px;

  @include breakpoint(md) {
    flex-direction: column;
    margin-bottom: 16px;
  }
}

.summaryBox {
  position: relative;
  flex: 1 1 30%;
  height: 75px;
  padding: 16px;
  border: 1px solid $color-gray-border;
  background: $color-white;

  @include breakpoint(md) {
    margin: 0 16px;
    flex: 1;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &.active {
      border: 2px solid $color-secondary;
    }
  }
}

.summaryName {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;

  @include breakpoint(md) {
    color: $color-primary;
  }
}

.summaryCounter {
  position: absolute;
  display: inline-block;
  top: 10px;
  right: 32px;
  font-size: 40px;
  font-weight: 700;

  @include breakpoint(md) {
    position: static;
  }
}

.tabsHeader {
  display: flex;
  position: relative;
  gap: 10px;

  @include breakpoint(md) {
    display: none;
  }
}

.tabsBox {
  display: flex;
  position: relative;
  flex: 1 1 30%;
  height: 45px;
  padding: 8px 16px;
  border: 1px solid $color-gray-border;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  background: $color-gray-background;

  &.active {
    height: 47px;
    margin-top: -2px;
    border: none;
    background: $color-white;
  }
}

.tableWrapper {
  th {
    padding: 10px 18px;
    background-color: $color-white;
    border-bottom: 1px solid $color-gray-border;
  }

  td {
    padding: 10px 18px;

    &:first-child {
      border-right: 1px dashed $color-gray-border;
    }
  }

  .expanded-row {
    td {
      border-right: none;
    }
  }
}

.light {
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: $color-text-light;
}

.mobileListItem {
  margin-bottom: 8px;
  background: $color-white;

  .itemHeader {
    padding: 16px;
    color: $color-primary;
    border-bottom: 1px solid $color-gray-border;
  }

  .itemBody {
    padding: 16px 0;
    display: flex;
    justify-content: space-between;

    > div {
      border-right: 1px solid $color-gray-border;
      padding: 0 16px;
      text-align: center;
      flex: 1;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
        border-right: none;
      }
    }
  }

  .label {
    color: $color-text-light;
    display: block;
  }
}

@import 'src/theme/color';

.wrapperComponent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 32px 0;
  background-color: $color-gray;

  .logo {
    margin-bottom: 50px;
  }
}

@import 'src/theme/color';
@import 'src/theme/font';


.wrapperComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
};

.formElement {
  width: 100%;
  &.formElementHalfWidth {
    width: calc(50% - 10px);
  }

  margin-bottom: 12px;

  label {
    font-weight: 700;
  }
}

.actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;

  button + button {
    margin-left: 16px;
  }
};

.textarea {
  padding: 16px;
  width: 100%;
  border: 1px solid $color-gray-border;

  font-size: 14px;
  font-family: $font-poppins;
};

.wrapperComponent {
  h2 {
    margin-top: 20px;
    text-align: center;
  }
}

.form {
  margin: 20px auto;
  width: 100%;
  max-width: 300px;

  .actions {
    margin: 16px 0;
    text-align: right;
  }
}

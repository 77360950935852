@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: block;
}

.button {
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
  width: auto;
  cursor: pointer;

  &.active {
    color: $color-primary;

    .countCarts {
      color: $color-primary;
    }
    .price {
      color: $color-primary;
    }
  }

  .icon {
    font-size: 20px;
  }

  .arrow {
    display: block;
    margin-left: 10px;

    &.rotated {
      transform: rotate(180deg);
    }
  }
}

.detailsCart {
  display: block;
  margin-left: 13px;
  margin-right: 16px;
}

.countCarts {
  display: block;
  margin-bottom: 2px;
  font-size: 12px;
  line-height: 14px;
  color: $color-text;
}

.price {
  display: block;
  font-size: 12px;
  line-height: 14px;
  color: $color-text-dark;
}

.cartWrapper {
  width: 700px;
  padding: 0 20px;

  @include breakpoint(sm) {
    width: 100%;
  }
}

.cartHeader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  font-weight: 700;
  font-size: 16px;

  .arrow {
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }
}

.cartBody {
  max-height: 300px;
  overflow: auto;

  .emptyCart {
    text-align: center;
    font-weight: 300;
    color: $color-text;
    padding: 50px 16px 75px;

    .title {
      display: block;
      font-size: 16px;
      font-weight: 600;
      color: $color-text-dark;
      line-height: 30px;
    }
  }
}
.info {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  gap: 16px;
  text-decoration: none;
  background-color: $color-order-bg;
  padding: 12px 0;
  color: $color-bestseller;

  svg {
    color: $color-secondary;
  }

  .freeShipping {
    padding-left: 6px;
    font-size: 12px;
    color: $color-text-dark;
  }
}

.cartFooter {
  .total {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    .label {
      font-weight: 700;
    }

    .prices {
      width: 200px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      flex-direction: column;
      color: $color-text-dark;

      .gross {
        font-size: 12px;

        span {
          font-size: 16px;
          font-weight: 700;
        }
      }

      .net {
        font-size: 10px;

        span {
          font-size: 12px;
        }
      }
    }
  }

  .actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;

    a {
      color: $color-primary;
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(50% - 10px);
      height: 40px;
      text-decoration: none;
      font-weight: 500;

      &.primary {
        color: $color-white;
        background: $color-primary;
      }
    }
  }
}

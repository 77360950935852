@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.componentWrapper {
  background: $color-gray-background;
  width: 100%;
  padding-bottom: 20px;
}

.content {
  padding: 50px 100px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include breakpoint(md) {
    padding: 16px;
    flex-direction: column-reverse;
  }

  h1 {
    font-family: $font-poppins;
    font-weight: 700;
    font-size: 40px;
  }

  h2 {
    font-family: $font-poppins;
    font-weight: 700;
    font-size: 32px;
  }

  .orderNo {
    margin: 24px 0;
    font-size: 16px;
    color: $color-secondary;
  }

  .description {
    font-size: 18px;
    color: $color-text;
  }
}

.image {
  display: block;
  margin-left: 50px;

  @include breakpoint(md) {
    margin-left: 0;
    width: 200px;
  }
}

.button {
  border: none;
  cursor: pointer;
  margin: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 40px;
  color: $color-white;
  background: $color-secondary;
  font-size: 16px;

  svg {
    margin-left: 40px;
  }
}

.tilesWrapper {
  margin-bottom: 50px;

  @include breakpoint(md) {
    padding: 0 16px;
  }

  .title {
    font-family: $font-poppins;
    font-weight: 700;
    font-size: 32px;
  }

  .tiles {
    margin: 28px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(md) {
      flex-direction: column;
    }

    .tile {
      width: calc(33% - 2px);
      position: relative;

      img {
        height: 250px;
      }

      @include breakpoint(md) {
        width: 100%;
        margin-bottom: 8px;
      }

      .label {
        font-size: 24px;
        padding: 30px 35px;
        color: $color-primary;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #ffffff90;
        cursor: pointer;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  .readMore {
    text-align: right;

    a {
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      color: $color-text-dark;

      svg {
        margin-left: 8px;
      }
    }
  }
}

.loaderWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba($color: $color-white, $alpha: 0.7);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapperComponent {
  padding: 30px 0;
  h3 {
    text-align: center;
  }

  .actions {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
}

@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 700px;
  height: 44px;
  border: 1px solid $color-gray-border;
  border-radius: 4px;
  position: relative;
  z-index: 99;

  @media screen and (min-width: 900px) and (max-width: 1200px) {
    min-width: 550px;
  }

  @include breakpoint(md) {
    &.focus {
      position: fixed;
      width: 100%;
      top: 0;
      border: none;
      .icon {
        order: 2;
        background: white;
      }
    }
    border: 1px solid $color-table-border;
    width: 100%;
  }

  > input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    margin: 0 20px;
    flex: 1;
    color: $color-text-dark;
    z-index: 5;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    @include breakpoint(md) {
      order: 2;
      margin: 0;
    }

    &::placeholder {
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%;
    }
  }

  .icon {
    width: 51px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-text;
    border-left: 1px solid $color-gray-border;
    cursor: pointer;
    z-index: 5;
    &:first-child {
      padding: 10px;
    }

    @include breakpoint(md) {
      order: 1;
      border: none;
    }

    &.hide {
      display: none;
    }
  }

  .categories {
    display: flex;
    align-items: center;
    border-left: 1px solid $color-gray-border;
    height: 100%;
    padding: 0 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;

    @media screen and (min-width: 900px) and (max-width: 1200px) {
      padding: 0 0 0 8px;

      * {
        padding: 0;
      }
    }
  }

  .clearSearchKeyword {
    border: unset;
    background-color: transparent;
    cursor: pointer;
    height: 18px;
    z-index: 9999;

    svg {
      height: 18px;
      width: 18px;
    }

    * {
      stroke: $color-text;
    }
  }
}

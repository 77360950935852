@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.componentWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 24px;
  row-gap: 24px;
  width: 100%;

  @include breakpoint(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint(sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.payButton {
  text-decoration: none;
  color: $color-primary;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  border: unset;
}

.content {
  display: flex;
  flex-flow: wrap column;
  flex: 1;
  align-items: center;
}

.name {
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: $color-text;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  padding: 12px;
  border: 2px solid $color-gray-border;
}

.icon {
  display: block;
  width: 100%;
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border: 1px solid $color-gray-border;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;

  &:hover,
  &.active {
    border-color: $color-secondary;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.labelBold {
  font-size: 16px;
  font-weight: 700;
}

.valueTotalNet {
  color: $color-label;
  margin-left: 5px;

  span {
    color: $color-primary;
    font-family: $font-poppins;
    font-weight: 700;
    font-size: 18px;
    margin-right: 8px;
  }
}

@import "src/theme/breakpoints";

.wrapperComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 50px;

  @include breakpoint(md) {
    padding: 0 16px;
  }
}

.formElement {
  width: 100%;
  margin-bottom: 12px;

  label {
    font-weight: 700;
    display: block;
    padding-bottom: 2px;
  }
}

.actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;

  button + button {
    margin-left: 16px;
  }
}

@import "src/theme/color";

.wrapperComponent {
}

.productList {
  margin-bottom: 32px;
  max-height: 200px;
  overflow: auto;
}

.product {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;

  .image {
    width: 68px;
    height: 68px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    margin-right: 20px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .description {
    flex: 1;

    .small {
      display: block;
      font-size: 12px;
      color: $color-text-light;
    }
  }

  .price {
    text-align: right;
    font-size: 18px;

    .small {
      display: block;
      font-size: 14px;
      color: $color-text-light;
    }
  }
}

.error {
  font-size: 12px;
  color: $color-error;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;

  button + button {
    margin-left: 16px;
  }
}

@import 'src/theme/color';

.inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  textarea {
    width: 100%;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;

  button:nth-child(2) {
    margin-left: 20px;
  }
}

.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 2px;
  border: 1px dashed $color-label;
  cursor: pointer;

  p {
    display: flex;
    align-items: center;

    svg {
      margin-left: 5px;
    }
  }
}

.fileWrapper {
  display: flex;
  justify-content: center;

  > div {
    position: relative;

    img {
      max-height: 100px;
    }

    button {
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      background-color: $color-white;
      box-shadow: 1px 1px 4px 0 rgba($color-black, 0.5);
      border: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
